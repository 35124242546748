import React from "react";
import classNames from "classnames";
import { Container, Text, Button, AnimateIn } from "@atoms";

const Cta = ({
  bgColor,
  button,
  className: _className,
  heading,
  descriptor,
  download,
}) => {
  return (
    <section
      className={classNames("py-20 md:py-28", {
        "bg-gray": bgColor === "gray",
        "bg-green": bgColor === "green",
        "bg-white": bgColor === "white",
        "border-t-2 border-black": bgColor !== "white",
      })}
    >
      <Container variant={descriptor ? "md" : "xs"}>
        <AnimateIn
          preset="fadeUpFast"
          className="flex flex-col gap-8 md:flex-row md:gap-12"
        >
          <div className="flex flex-col items-start gap-12">
            <Text
              className={classNames(
                "text-3xl font-bold leading-tight md:text-4xl",
                {
                  "md:pr-20": descriptor,
                }
              )}
            >
              {heading}
            </Text>
            {!descriptor && button?.url && (
              <Button
                to={button.url}
                color={bgColor === "green" ? "black" : "green"}
                download={button.download || button.type === "asset"}
              >
                {button.text}
              </Button>
            )}
          </div>
          {descriptor && (
            <div className="flex flex-col items-start space-y-8 pl-0 md:border-l-2 md:border-black md:pl-20">
              <Text variant="body">{descriptor}</Text>
              {button?.url && (
                <Button
                  to={button.url}
                  color={bgColor === "green" ? "black" : "green"}
                  download={download || button.type === "asset"}
                >
                  {button.text}
                </Button>
              )}
            </div>
          )}
        </AnimateIn>
      </Container>
    </section>
  );
};

Cta.defaultProps = {
  bgColor: "white",
  button: {
    url: "/",
    text: "Button",
  },
  copy: "<p>We nurture and invest in artists and the arts throughout Santa Cruz County.</p>",
};

export default Cta;
